.awards-area-start-one {
    .title-area-center {
        p.disc {
            max-width: 50%;
            margin: auto;

            @media #{$sm-layout} {
                max-width: 100%;
            }
        }
    }
}


.single-awards-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 0;
    border-bottom: 1px solid rgba(97, 94, 252, 0.2);

    .title {
        margin: 0;
        font-size: 32px;
        transition: .3s;

        @media #{$sm-layout} {
            font-size: 24px;
        }

        @media #{$small-mobile} {
            font-size: 18px;
        }
    }

    .right-logo {
        img {
            @media #{$sm-layout} {
                max-width: 100px;
            }
        }
    }

    &:hover {
        .title {
            padding-left: 15px;
            border-left: 5px solid var(--color-primary);
        }
    }
}

.rts-success-story-area {
    .vedio-icone {
        position: relative;

        img.mous {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            max-width: 100px;
            height: auto;
            pointer-events: none;
            transition: .3s;
        }

        &:hover {
            img.mous {
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }
}


.mySwiper-banner-seven {
    position: relative;

    .bottom-star-area {
        display: flex;
        align-items: center;
        gap: 70px;
        top: auto;
        bottom: 64px;
        position: absolute;
        z-index: 10;
        left: 320px;

        @media #{$laptop-device} {
            left: 100px;
        }

        @media #{$smlg-device} {
            left: 30px;
        }

        @media #{$large-mobile} {
            flex-direction: column;
            gap: 20px;
            left: 15px;
        }

        .star-area-left {
            span {
                font-weight: 400;
                color: #111032;
            }

            .top {
                display: flex;
                align-items: center;
                gap: 13px;
                margin-bottom: 15px;


            }

            .wrapper {
                display: flex;
                align-items: center;
                gap: 2px;
                margin-bottom: 0;

                .single {
                    height: 24px;
                    width: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #00B67A;

                    i {
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 16px;

            .trusted-patient {
                .title {
                    font-weight: 600;
                    margin-bottom: 0;
                    font-size: 24px;
                    font-family: var(--font-secondary);
                }
            }
        }
    }
}
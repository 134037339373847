.select-area-down {
    padding: 10px 6px 10px 25px;
    background: #fff;
    border-radius: 33px;

    height: 56px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(97, 94, 252, 0.2);
    justify-content: space-between;
    max-width: 520px;

    .rts-btn {
        padding: 14px 24px;
    }

    @media #{$smlg-device} {
        max-width: max-content;
    }

    @media #{$sm-layout} {
        flex-wrap: wrap;
        height: auto;
        border-radius: 5px;
    }

    @media #{$large-mobile} {
        padding: 10px 6px 10px 6px;
    }

    ul li.options {
        width: 100%;
        height: 24px;
        line-height: 24px;
        background-color: #fff;
        border: none;
        border-radius: 3px;
        margin-bottom: 5px;
        padding: 0 10px;
        position: absolute;
        left: 0;
        min-width: max-content;
        color: #6B6B6B;
        cursor: pointer;
    }

    ul li.options:hover {
        background-color: #eee;
    }

    select {
        border: none;
    }

    form {
        width: 170px;
    }
}
// feature area style

.rts-footer-area {
    .rts-btn {
        padding: 14px 26px;
    }
}

.mr-dec-feature {
    margin-right: -40px;

    @media #{$mdsm-layout} {
        margin-right: 0;
    }
}

.ml-dec-feature {
    margin-left: -40px;

    @media #{$mdsm-layout} {
        margin-left: 0;
    }
}

.thumbnail-main-feature-1 {
    margin-top: -50px;

    @media #{$mdsm-layout} {
        margin-top: 0;
    }
}

.feature-signle-sm-card {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgba(97, 94, 252, 0.2);
    gap: 14px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    .details {
        .title {
            font-size: 20px;
            margin: 0;
        }
    }
}
// blog area start

.rts-single-blog {
    .thumbnail {
        display: block;
        overflow: hidden;
        border-radius: 10px;

        img {
            transition: .3s;
        }
    }

    &:hover {
        .thumbnail {
            img {
                transform: scale(1.1);
            }
        }
    }

    .inner-content {
        margin-top: 25px;

        .author-area {
            display: flex;
            align-items: center;
            gap: 34px;

            span {
                font-size: 16px;
                font-weight: 400;
                position: relative;

                &.name {
                    &::after {
                        position: absolute;
                        right: -17px;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        background: #D9D9D9;
                        content: '';
                    }
                }
            }
        }
    }

    a.button-wrapper {
        margin-top: 15px;
        display: block;
        padding-bottom: 28px;
        border-bottom: 1px solid rgba(97, 94, 252, 0.2);
        margin-bottom: 28px;

        .title {
            font-size: 24px;
            transition: .3s;
            margin-bottom: 0;

            @media #{$large-mobile} {
                font-size: 18px;
            }
        }

        &:hover {
            .title {
                color: var(--color-primary);
            }
        }
    }
}



.single-blog-list-area {
    .thumbnail {
        display: block;
        overflow: hidden;
        border-radius: 10px 10px 0 0;

        img {
            transition: .3s;
            width: 100%;
        }
    }

    &:hover {
        .thumbnail {
            img {
                transform: scale(1.1);
            }
        }
    }

    .inner-content-area {
        padding: 40px 50px;
        border: 1px solid rgba(97, 94, 252, 0.2);
        border-radius: 0 0 10px 10px;

        @media #{$sm-layout} {
            padding: 25px;
        }

        .tag-author-area {
            display: flex;
            align-items: center;
            gap: 30px;

            @media #{$large-mobile} {
                flex-wrap: wrap;
                gap: 10px;
            }

            .single {
                display: flex;
                align-items: center;
                gap: 10px;

                i {
                    color: var(--color-primary);
                }
            }
        }

        a {
            margin-top: 25px;
            display: block;
            margin-bottom: 35px;

            .title {
                font-size: 34px;
                line-height: 44px;
                transition: .3s;

                @media #{$sm-layout} {
                    font-size: 26px;
                    line-height: 1.3;
                }

                @media #{$large-mobile} {
                    font-size: 20px;
                }
            }

            &:hover {
                .title {
                    color: var(--color-primary);
                }
            }
        }

        .rts-btn {
            padding: 18px 45px;
            margin: 0;
        }
    }
}



.single-sidebar-wized {
    padding: 35px 30px;
    border-radius: 10px;
    border: 1px solid rgba(97, 94, 252, 0.2);
    margin-bottom: 40px;

    @media #{$large-mobile} {
        padding: 15px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .search {
        position: relative;

        input {
            height: 50px;
            border-radius: 10px;
            border: 1px solid rgba(97, 94, 252, 0.2);
            color: #7D7D7D;

            &:focus {
                border: 1px solid var(--color-primary);
            }
        }

        i {
            position: absolute;
            right: 18px;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .title-area {
        margin-bottom: 25px;

        .title {
            font-size: 24px;
        }
    }

    .single-service {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid rgba(97, 94, 252, 0.2);
        padding: 15px 20px;
        transition: .3s;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            color: #111032;
            transition: .3s;
        }

        i {
            color: #111032;
            transition: .3s;
        }

        &:hover {
            background: var(--color-primary);

            p {
                color: #fff;
            }

            i {
                color: #fff;
            }
        }
    }
}

.signle-post-short-area {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    .thumbnail {
        display: block;
        overflow: hidden;
        max-width: 85px;
    }

    .infor {
        .time {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        .title {
            font-size: 18px;
            margin: 0;
            margin-top: 10px;
            line-height: 24px;
            transition: .3s;

            @media #{$large-mobile} {
                font-size: 18px;
            }

            &:hover {
                color: var(--color-primary);
            }
        }
    }
}

.tag-wrapper-small {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .single-tag {
        padding: 6px 15px;
        border: 1px solid rgba(97, 94, 252, 0.2);
        border-radius: 6px;
        font-size: 14px;
        color: #74787C;
        cursor: pointer;
        transition: .3s;
        font-weight: 500;

        &:hover {
            background: #615EFC;
            color: #fff;
        }
    }
}

.blog-details-main-wrapper {
    .tag-author-area {
        display: flex;
        align-items: center;
        gap: 30px;
        flex-wrap: wrap;

        @media #{$large-mobile} {
            gap: 10px;
        }

        .single {
            display: flex;
            align-items: center;
            gap: 10px;

            i {
                color: var(--color-primary);
            }
        }
    }

    .thumbnail-main {
        img {
            width: 100%;
        }
    }

    .inner-content {
        padding: 40px 50px;
        border: 1px solid rgba(97, 94, 252, 0.2);

        @media #{$sm-layout} {
            padding: 25px;
        }

        @media #{$large-mobile} {
            padding: 15px;
        }

        .title {
            margin-top: 25px;
            display: block;
            line-height: 44px;

            @media #{$large-mobile} {
                line-height: 1.3;
            }
        }

        .quote-area {
            padding: 25px 40px;
            border-radius: 10px;
            border: 1px solid rgba(97, 94, 252, 0.2);
            margin-bottom: 35px;

            @media #{$large-mobile} {
                padding: 15px;
            }

            p {
                font-size: 18px;
                color: #30373E;
                font-weight: 500;
                line-height: 26px;
                font-family: var(--font-seconday);
                margin: 0;
                margin-bottom: 0 !important;
            }
        }

        p.disc {
            margin-bottom: 23px;
        }
    }
}

.tag-share-wrapper-blog-details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .tag-area {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        span {
            color: #30373E;
            font-size: 18px;
            font-weight: 600;
        }

        button {
            max-width: max-content;
            padding: 11px 17px;
            border-radius: 30px;
            transition: .3s;

            &:hover {
                background: var(--color-primary);
                color: #fff;
            }
        }
    }

    .social-area {
        display: flex;
        align-items: center;
        gap: 12px;

        span {
            color: #30373E;
            font-size: 18px;
            font-weight: 600;
        }

        ul {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 12px;
            list-style: none;

            li {
                a {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #F0F0FF;
                    transition: .3s;

                    i {
                        transition: .3s;
                        color: #111032;
                    }

                    &:hover {
                        background: var(--color-primary);

                        i {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}


.blog-author-area-main {
    margin-top: 40px;
    padding-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
}

.blog-author-area-main {
    display: flex;
    align-items: center;
    gap: 30px;

    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .thumbnail {
        min-width: max-content;

        img {
            min-width: max-content;
        }
    }

    .information {
        span {
            margin: 0;
        }

        .title {
            margin: 0;
        }

        p.disc {
            margin: 0;
        }
    }
}


.comment-form-details {
    title {
        font-size: 30px;
    }

    .input-half-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
    }

    input {
        height: 60px;
        margin-bottom: 15px;
    }

    textarea {
        height: 140px;
        border: 1px solid rgba(97, 94, 252, 0.2);
        padding: 13px;

        &:focus {
            border: 1px solid var(--color-primary);
        }
    }

    button {
        margin-top: 20px;
    }
}
.container-full-header {
    max-width: 1760px;
    margin: auto;

    @media #{$extra-device} {
        padding: 0 15px;
    }

    @media #{$laptop-device} {
        padding: 0 15px;
    }

    @media #{$smlg-device} {
        padding: 0 15px;
    }
}

.header-one {
    .header-top-area {
        background: var(--color-primary);

        .header-top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media #{$smlg-device} {
                display: none;
            }

            .left {
                display: flex;
                align-items: center;
                gap: 50px;

                .map-area {
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    gap: 7px;

                    i {
                        color: #fff;
                        font-size: 18px;
                    }

                    a {
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }

            .right {
                display: flex;
                align-items: center;
                gap: 50px;

                .map-area {
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    gap: 7px;

                    i {
                        color: #fff;
                        font-size: 18px;
                    }

                    a {
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.header-wrapper-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .logo {
        padding: 20px 0;
    }

    .header-right {
        display: flex;
        align-items: center;
        gap: 20px;

        input {
            height: 44px;
            border-radius: 100px;
            font-size: 14px;
            width: 240px;

            @media #{$smlg-device} {
                width: 250px;
            }

            @media #{$sm-layout} {
                width: 150px;
            }

            @media #{$large-mobile} {
                width: 150px;
            }
        }

        .input-area {
            position: relative;

            @media #{$sm-layout} {
                display: none;
            }

            i {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                color: var(--color-primary);
            }
        }

        .cart-button {
            i {
                color: var(--color-primary);
            }
        }
    }
}

.logo-area-start {
    display: flex;
    align-items: center;
    gap: 98px;

    @media #{$laptop-device} {
        gap: 30px;
    }

    @media #{$smlg-device} {
        gap: 25px;
    }
}

.text-clip {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: #000;
    font-family: var(--font-secondary);
}

.header--sticky {
    transition: .3s;
}

.header--sticky.sticky {
    position: fixed !important;
    top: 0;
    display: block;
    width: 100%;
    box-shadow: 0px 7px 18px rgba(24, 16, 16, 0.0509803922);
    background: #fff;
    z-index: 999;
}

.title-area-center {
    text-align: center;

    .pre {
        border-radius: 30px;
        padding: 5px 16px;
        color: #615EFC;
        margin-bottom: 30px;
        text-align: center;
        display: block;
        max-width: max-content;
        margin: auto;
        border: 1px solid rgba(97, 94, 252, 0.2);
    }

    .title {
        margin-top: 14px;
    }
}

.thumbnail-appoinment {
    height: 100%;
    border-radius: 0 10px 10px 0;
    overflow: hidden;

}

.thumbnail-appoinment img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.header-top-area-2 {
    border-bottom: 1px solid rgba(97, 94, 252, 0.2);
}

.header-top-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    @media #{$mdsm-layout} {
        padding: 10px 0;
    }

    .rightarea {
        display: flex;
        align-items: center;
        gap: 70px;

        @media #{$smlg-device} {
            gap: 25px;
        }

        .single-contact-area {
            display: flex;
            align-items: center;
            gap: 16px;
            position: relative;

            @media #{$mdsm-layout} {
                display: none;
            }

            &::after {
                position: absolute;
                content: '';
                right: -36px;
                height: 36px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                background: rgba(97, 94, 252, 0.2);

                @media #{$smlg-device} {
                    display: none;
                }
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            i {
                color: var(--color-primary);
                font-size: 32px;
            }

            .information {
                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #111032;
                    line-height: 0;
                }
            }
        }
    }
}

header.header-one.style-two.header--sticky {
    background: #fff;
}

.header-one.style-two {
    .header-wrapper-1 {
        @media #{$smlg-device} {
            display: none;
        }
    }
}

.header-one.style-two {
    .header-top-area-2 {
        transition: .3s;
    }
}

.header-one.style-two.sticky {
    .header-top-area-2 {
        display: none;
        transition: .3s;
    }
}

.menu-btn {
    display: none;
    height: 45px;
    width: 45px;
    background: var(--color-primary);
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;

    @media #{$smlg-device} {
        display: block;
        display: flex;

        rect {
            fill: #fff;
        }
    }
}

.has-dropdown {
    &::after {
        position: absolute;
        content: '\f078';
        right: -22px;
        top: 53%;
        transform: translateY(-50%);
        font-weight: 400;
        color: #111032;
        font-family: "Font Awesome 6 Pro";
        font-weight: 300;
        font-size: 14px;
    }

    &:hover {
        &::after {
            color: var(--color-primary);
            content: '\f077';
        }
    }

    &.menu-item-open {
        &::after {
            color: var(--color-primary);
            content: '\f077';
        }
    }
}

.submenu.parent-nav {
    &>li {
        margin: 6px 0;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}


// date picker

#ui-datepicker-div {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.46);
    display: none;

    tr {
        td {
            transition: .3s;

            a {
                text-decoration: none;
            }

            &:hover {
                background: var(--color-primary);

                a {
                    color: #fff;
                }
            }
        }
    }

    .ui-datepicker-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
    }
}

.rts-mega-menu {
    .mega-menu-item {
        gap: 7px;

        li {
            a {
                &:hover {
                    background: var(--color-primary) !important;
                    color: #fff;
                }

                &.active {
                    border-radius: 2px;
                }
            }
        }
    }


}

.has-dropdown.mega-menu {
    &::after {
        display: none;
    }

    &>a {
        position: relative;

        &::after {
            position: absolute;
            content: "\f078";
            right: -22px;
            top: 53%;
            transform: translateY(-50%);
            font-weight: 400;
            color: #111032;
            font-family: "Font Awesome 6 Pro";
            font-weight: 300;
            font-size: 14px;
        }
    }

    &.menu-item-open {
        &>a {
            &::after {
                content: "\f077";
            }
        }
    }
}
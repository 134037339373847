.container {
    max-width: 1310px;
    margin: auto;

    @media #{$laptop-device} {
        padding: 0 15px;
    }
}

// sigle short service
.single-short-service {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    padding: 30px 20px;
    border-radius: 10px;
    border: 1px solid rgba(97, 94, 252, 0.2);

    .title {
        margin-bottom: 0;
        font-size: 24px;

        @media #{$mdsm-layout} {
            font-size: 18px;
        }
    }
}

.procedure-iamge-wrapper {
    .thumbnail {
        img {
            width: 100%;
        }
    }
}

.mid-hr {
    width: 1px;
    height: 100% !important;
    background: #e1e1ff;
    margin: auto;
    opacity: 1;
}

.single-service-area {
    padding: 30px 25px;
    border-radius: 10px;
    border: 1px solid rgba(97, 94, 252, 0.2);
    height: 100%;
    transition: .3s;
    background: #fff;

    .icon {
        margin-bottom: 36px;
    }

    .title {
        font-size: 23px;
        margin-bottom: 16px;
    }

    p.disc {
        font-size: 16px;
        margin-bottom: 10px;
    }

    &:hover {
        border: 1px solid var(--color-primary);
    }
}

.btn-transparent {
    color: #111032;
    font-weight: 400;
    transition: .3s;

    i {
        transform: rotate(-45deg);
        transition: .3s;
        position: relative;
        bottom: -1px;
        font-weight: 400;
    }

    &:hover {
        i {
            transform: rotate(-0deg);
        }
    }
}

.container-80 {
    max-width: 1760px;
    margin: auto;
}

.containerFlow {
    animation: containerFlow 1s linear forwards;
}

@keyframes containerFlow {
    from {
        max-width: 1920px;
        margin: auto;
    }

    to {
        max-width: 1760px;
        margin: auto;
    }
}


.bg-light {
    background-color: #F1F1FF !important;
    border-radius: 10px;
}

.swiper-container-h2 {
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;

    .swiper-pagination-bullet-active {
        background: var(--color-primary);
    }
}

.swiper-pagination-bullet-active {
    background: var(--color-primary);
    width: 20px;
    border-radius: 10px;
    height: 8px;
}

.team-swiper-container-h1 {
    position: relative;
    padding-bottom: 50px;

    .swiper-pagination-bullet-active {
        background: var(--color-primary);
    }
}

.single-service-area.style-bg-light {
    background: #F1F1FF;
    border: 1px solid rgba(97, 94, 252, 0.2);

    &:hover {
        border: 1px solid var(--color-primary);
    }

    .rts-btn {
        margin-top: 25px;

        i {
            transform: rotate(-45deg);
            font-weight: 400;
        }
    }

    &.border-trnasparent {
        border: 1px solid transparent;

        &:hover {
            border: 1px solid var(--color-primary);
        }
    }
}


.rts-service-area {
    position: relative;
    z-index: 1;

    .bg-shape {
        position: absolute;
        left: 60px;
        top: -30px;
        z-index: -1;
    }
}

.single-procedure {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    @media #{$small-mobile} {
        flex-direction: column;
    }

    .information-area {
        .title {
            margin-bottom: 5px;
        }
    }
}


.special-procedure-area {
    position: relative;

    z-index: 1;

    .bg-slape {
        position: absolute;
        left: 60px;
        top: 40px;
        z-index: -1;
    }
}



// service dental area start

.button-dental-bwtween-area {
    text-align: left;

    .title-area-left {
        text-align: left;

        .pre-title {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .title {
            margin-top: 20px;

            @media #{$large-mobile} {
                br {
                    display: none;
                }
            }
        }
    }
}

.single-dental-service-areas {
    position: relative;

    .inner-content {
        position: absolute;
        position: absolute;
        left: 40px;
        bottom: 15px;

        a {
            .title {
                min-width: max-content;
                color: #ffff;
                font-size: 26px;
            }
        }
    }
}

.round-btn {
    height: 45px;
    min-width: 45px;
    max-width: 45px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid #111032;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 25px;
    bottom: 30px;
    transition: .3s;

    i {
        color: #111032;
        transition: .3s;
    }

    &:hover {
        background: #111032;

        i {
            color: #fff;
        }
    }
}

.single-service-six {
    padding: 38px 33px 20px 28px;
    position: relative;
    height: 100%;

    &.bg-pink {
        background: #FFCFC5;
        border-radius: 10px;
    }

    &.bg-green {
        background: #ABFFB8;
        border-radius: 10px;
    }

    &.bg-yello {
        background: #FFEDAB;
        border-radius: 10px;
    }

    &.bg-sayan {
        background: #E8E8FF;
        border-radius: 10px;
    }

    .title {
        color: #111032;
    }

    p.disc {
        color: #111032;
        font-size: 16px;
    }
}

.single-procedure.style-six {
    padding: 15px;
    border: 1px solid rgba(97, 94, 252, 0.2);
    border-radius: 10px;
}

.single-service-medicle {
    display: flex;
    align-items: center;
    gap: 0;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .thumbnail {
        img {
            width: 100%;
        }
    }

    .title-area-rotate {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        transform: rotate(-90deg);
        right: -191px;
        left: auto;
        top: 192px;
        background: #f1f1ff;
        height: 81px;
        gap: 20px;
        padding: 15px 17px;
        border-radius: 0 0 10px 10px;
        border: 1px solid rgba(97, 94, 252, 0.2);
        width: 465px;
        transition: 0.3s;

        .icon {
            min-width: max-content;

            img {
                min-width: max-content;
                transform: rotate(90deg);
                transition: .3s;
            }
        }

        .title {
            min-width: max-content;
            font-size: 32px;
            margin: 0;
            font-weight: 400;
            color: #111032;
            transition: .3s;
        }
    }

    &:hover {
        .title-area-rotate {
            background: var(--color-primary);
            border: 1px solid var(--color-primary);

            .title {
                color: #fff;
            }

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(53%) saturate(571%) hue-rotate(204deg) brightness(114%) contrast(101%);
            }
        }
    }
}

.service-content-iamge {
    position: absolute;
    left: 100px;
    top: 100px;
    z-index: -1;
}

.service-area {
    .title-between-area {
        .rts-btn.btn-primary {
            padding: 10px 22px;
        }
    }
}

.single-service-area.style-bg-light {
    .rts-btn {
        padding: 12px 26px;
    }
}

.service-and-price-table {
    position: relative;

    .service-price-single.head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;

        p {
            margin: 0;
            font-size: 16px;
            color: #111032;
            font-weight: 500;
        }

        .price-area {
            padding-right: 70px;

            @media #{$sm-layout} {
                padding-right: 0;
            }
        }
    }

    .service-price-single.body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 15px;
        border-top: 1px solid rgba(97, 94, 252, 0.2);

        &:last-child {

            border-bottom: 1px solid rgba(97, 94, 252, 0.2);
        }

        p {
            margin: 0;
            color: #6B6B6B;
        }

        .price-area {
            padding-right: 50px;

            @media #{$mdsm-layout} {
                padding-right: 0;
            }
        }
    }

    &::after {
        position: absolute;
        content: '';
        right: 220px;
        height: calc(100% - 47px);
        top: 47px;
        width: 1px;
        background: rgba(97, 94, 252, 0.2);

        @media #{$smlg-device} {
            display: none;
        }
    }
}

.thumbnail-service-free {
    img {
        width: 100%;
    }
}

.thumbnail-service-free {
    img {
        border-radius: 10px;
    }
}

.content-wrapper-service-details {
    margin-top: 40px;

    .title {
        margin-bottom: 10px;
        font-size: 24px;
    }

    p {
        margin: 0;

        b {
            color: #111032;
            font-weight: 500;
        }
    }

    .check-area-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 50px;

        @media #{$large-mobile} {
            flex-direction: column;
            gap: 10px;
        }

        .left-wrapper {
            .single {
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 10px 0;

                p {
                    color: #111032;
                }
            }
        }
    }
}

.single-rightsidebar-single {
    .title {
        font-size: 24px;
    }
}

.single-department-service-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    border-radius: 10px;
    border: 1px solid rgba(97, 94, 252, 0.2);
    margin-bottom: 10px;
    transition: .3s;

    &:hover {
        border-color: rgba(97, 94, 252, 1);
        transform: translateY(-4px);
    }

    &:last-child {
        margin-bottom: 0;
    }

    .left-side {
        display: flex;
        align-items: center;
        appearance: none;
        gap: 12px;

        .icon {
            img {
                // min-width: max-content;
                max-width: 30px;
            }
        }

        .title {
            margin-bottom: 0;
            font-size: 18px;
        }
    }
}




.single-rightsidebar-single {
    .doctor-title-table {
        margin-top: 40px;

        .top {
            padding: 30px;
            background: var(--color-primary);
            border-radius: 10px 10px 0 0;
            position: relative;

            * {
                color: #fff;
            }

            p.disc {
                margin-bottom: 30px;
            }

            a.rts-btn.btn-primary {
                border: 1px solid #fff;
                color: #fff !important;
                padding: 11px 22px;

                &:hover {
                    border: 1px solid transparent;
                }
            }

            img {
                position: absolute;
                bottom: 5%;
                left: 50%;
            }
        }

        .bottom {
            padding: 30px;
            background-color: #F1F1FF;
            border-radius: 0 0 10px 10px;

            .title {
                font-size: 24px;
                color: var(--color-primary);
            }

            .single-table {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 0;
                border-bottom: 1px solid rgba(97, 94, 252, 0.2);
            }
        }
    }
}

.thumbnail-image-right-cardio {
    margin-top: 40px;
    position: relative;

    img {
        width: 100%;
    }

    .inner {
        position: absolute;
        left: 23px;
        bottom: 38px;

        .title {
            color: #fff;
            font-size: 24px;
        }

        .rts-btn {
            background: #fff !important;
            color: #111032;
        }
    }
}

.meet-doctor-area-sd {
    .single-team-area-start {
        .thumbnail {
            img {
                border: 1px solid rgba(97, 94, 252, 0.2);
                display: block;
                border-radius: 10px;
            }
        }
    }
}

.patient-success-portfolio {
    .rts-btn.btn-primary.border.bg-transparent {
        border: 1px solid#fff !important;
        color: #fff !important;
        padding: 12px 27px;
        margin-top: 150px;

        @media #{$sm-layout} {
            margin-top: 60px;
        }

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(564%) hue-rotate(31deg) brightness(116%) contrast(100%);
        }
    }
}

.rts-single-blog .rts-btn.btn-primary.border.bg-transparent {
    padding: 11px 26px;
    border: 1px solid rgba(97, 94, 252, 0.2) !important;
    color: #111032;
}

.right-whychoose-us-style-one {
    position: relative;

    .thumbnail-image {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 438px;

        img {
            @media #{$large-mobile} {

                max-width: 320px;
            }
        }
    }

    .inner-content {
        .top {
            position: absolute;
            left: 40px;
            top: 40px;

            .title {
                color: #fff;
                font-size: 80px;
                margin-bottom: 3px;

                @media #{$mdsm-layout} {
                    margin-bottom: 15px;
                }

                @media #{$large-mobile} {
                    font-size: 40px;
                }
            }

            .time-shedule {
                display: flex;
                align-items: center;
                gap: 8px;

                span {
                    font-size: 48px;
                    font-weight: 400;
                    color: #fff;

                    @media #{$large-mobile} {
                        font-size: 28px;
                    }
                }

                img {
                    @media #{$large-mobile} {
                        max-width: 30px;
                    }
                }
            }
        }


        .call-us {
            position: absolute;
            left: 30px;
            bottom: 30px;
            display: flex;
            align-items: center;
            gap: 10px;

            span {
                color: #fff;
                font-size: 18px;
            }
        }
    }
}


.service-details-surgery-main {
    h2.title {
        font-size: 36px;
        line-height: 1.3;
        margin-top: 40px;
        @media #{$large-mobile} {
            font-size: 20px;
        }
    }


}

.service-details-surgery {
    .container-wrapper-faq {
        max-width: 100% !important;
    }
}

.check-area-service-details {
    .single-check {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        margin-bottom: 12px;
    }
}

.bg-light-1 {
    background: #F8F8FF;
}

.card-transparent-service {
    .single-service-area {
        background: transparent;
    }
}

.rts-large-image-area-9 {
    height: 850px;
    background-image: url(../images/video/01.webp);
    background-attachment: fixed;
    position: relative;

    .rating-area-card {
        position: absolute;
        width: 344px;
        height: 251px;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(25px);
        border-radius: 10px 0px 0px 0px;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .inner {
            text-align: center;

            .title {
                font-size: 128px;
                margin: 0;
                color: #fff;
                margin-top: -45px;
                line-height: 1.2;
            }
        }
    }
}
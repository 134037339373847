// social-area

.social-area-tranaparent {
    display: flex;
    align-items: center;
    gap: 20px;

    span {
        color: #fff;
    }

    ul {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 12px;
        list-style: none;

        li {
            margin: 0;

            a {
                i {
                    color: #fff;
                }
            }
        }
    }
}
// procedure area start
.procedure-single-content-wrapper {
    h2.title {
        font-size: 36px;
        margin-bottom: 30px;
    }

    .cottom-single {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .title {
            font-size: 24px;
            margin-bottom: 15px;
        }

        p.disc {
            max-width: 80%;
            margin: 0;
        }
    }
}


.portfolio-detials-wrapper {
    .thumbnail-large-image {
        .thumbnail {
            display: block;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .thumbnail-bottom-wrapper {
            height: 80px;
            background: var(--color-primary);
            display: flex;
            align-items: center;
            gap: 80px;
            padding: 40px;

            @media #{$mdsm-layout} {
                gap: 30px;
                padding: 20px;
                flex-wrap: wrap;
                height: auto;
            }

            .single-info {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            span {
                color: #fff;
            }

            i {
                color: #fff;
            }
        }
    }
}



.bottom-content-area {
    .title {
        font-size: 24px;
    }

    span {
        color: var(--color-primary);
        font-size: 20px;
    }

    .list-single {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin: 18px 0;

        img {
            position: static;
            margin-bottom: -2px;
        }

        p {
            max-width: 924px;
            position: relative;
            margin-top: -2px;
        }
    }
}
// about
.about-image-left-wrapper {
    position: relative;
    margin-right: -30px;

    .review-area {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 15px 20px;
        border-radius: 10px 0 0 0;

        .stars-area {
            margin-top: 13px;

            .wrapper {
                display: flex;
                align-items: center;
                gap: 2px;
                margin-bottom: 10px;

                .single {
                    height: 24px;
                    width: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #00B67A;

                    i {
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }

            .content {
                color: #111032;

                span {
                    font-size: 14px;
                    color: #111032;
                }
            }
        }
    }
}

.title-wrapper-left {
    text-align: left;

    .pre {
        border-radius: 30px;
        padding: 5px 16px;
        color: #615EFC;
        margin-bottom: 21px;
        display: block;
        max-width: max-content;
        border: 1px solid rgba(97, 94, 252, 0.2);
    }

    .title {
        text-align: left;
        font-size: 48px;

        @media #{$laptop-device} {
            font-size: 36px;
        }

        @media #{$smlg-device} {
            font-size: 32px;
        }

        @media #{$sm-layout} {
            font-size: 26px;

            br {
                display: none;
            }
        }

        @media #{$large-mobile} {
            font-size: 22px;
        }
    }

    p.disc {
        font-size: 16px;
    }
}

.button-wrapper {
    display: flex;
    align-items: center;
    gap: 54px;

    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .signature {
        gap: 15px;
        display: flex;
        align-items: center;

        img {
            max-width: max-content;

            &.one {
                border-radius: 50%;
                padding: 4px;
                border: 1px solid rgba(97, 94, 252, 0.2);
            }

            &.sign {
                max-width: 105px;
                height: fit-content;
            }
        }
    }
}

.about-content-wrapper-right {
    p.disc {
        text-align: left;
        font-size: 16px;
        max-width: 95%;
    }
}

.radious-0 {
    border-radius: 0 !important;
}

.why-choose-us-main-wrapper {
    margin-top: 40px;

    .single-choose-us {
        margin-bottom: 20px;
        padding: 22px 20px;
        border-radius: 20px;
        border: 1px solid rgba(97, 94, 252, 0.2);
        display: flex;
        align-items: center;
        gap: 20px;

        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .info {
            .title {
                font-size: 20px;
                margin-bottom: 8px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

.review-area.flex {
    .stars-area {
        display: flex;
        align-items: center;
        gap: 30px;

        .wrapper {
            display: flex;
            align-items: center;
        }

        .wrapper {
            display: flex;
            align-items: center;
            gap: 2px;
            margin-bottom: 10px;

            .single {
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #00B67A;

                i {
                    color: #fff;
                    font-size: 14px;
                }
            }
        }

        .content {
            color: #111032;

            span {
                font-size: 16px;
                color: #111032;
                font-weight: 500;
            }
        }
    }
}


.single-check-wrapper-main-about {
    display: flex;
    align-items: center;
    gap: 50px;

    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .single {
        margin: 15px 0;
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            min-width: max-content;
        }

        span {
            font-weight: 600;
            font-size: 18px;
            color: #111032;
            font-family: var(--font-primary);
        }
    }
}


.about-image-left-wrapper.two {
    position: relative;

    .thumbnail-main {
        img {
            @media #{$mdsm-layout} {
                width: 100%;
            }
        }
    }

    .patient-number {
        position: absolute;
        left: 30px;
        bottom: -50px;
        background: var(--color-primary);
        padding: 15px 22px;
        border-radius: 10px;
        width: 182px;
        z-index: 1;

        &::after {
            position: absolute;
            content: '';
            left: 9px;
            top: 9px;
            right: 9px;
            bottom: 90px;
            width: calc(100% - 18px);
            height: calc(100% - 18px);
            background: rgba(118, 115, 251, 0.8);
            backdrop-filter: blur(2px);
            z-index: -1;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.2);
        }

        .title {
            color: #fff;
            line-height: 1;
        }

        .top {
            .title {
                margin-bottom: 0;
                font-size: 48px;
                margin-bottom: 0;
            }

            span {
                font-size: 24px;
                color: #fff;
            }
        }

        .bottom {
            .title {
                font-size: 26px;
                margin-bottom: 0;
            }

            span {
                font-size: 16px;
                color: #fff;
            }
        }
    }

}

.about-four-content-4 {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    position: relative;
    height: 100%;

    .thumbnail {
        height: 100%;

        img {
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .small-image-right-side {
        display: flex;
        flex-direction: column;

        .counbter-about {
            background: #615EFC;
            border-radius: 10px;
            padding: 10px 15px;
            margin-bottom: 15px;

            .title {
                color: #fff;
                font-size: 48px;
                margin-bottom: 5px;

                span {
                    color: #fff;
                    font-size: 48px;
                    margin-bottom: 5px;
                }
            }

            span {
                color: #fff;
                font-size: 18px;
            }
        }

        .review-area {
            position: relative;
            right: 0;
            bottom: 0;
            padding: 0;
            border-radius: 10px 0 0 0;

            .stars-area {
                margin-top: 13px;

                .wrapper {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    margin-bottom: 10px;

                    .single {
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #00B67A;

                        i {
                            color: #fff;
                            font-size: 14px;
                        }
                    }
                }

                .content {
                    color: #111032;

                    span {
                        font-size: 14px;
                        color: #111032;
                    }
                }
            }
        }
    }
}

.about-four-content {
    .short-service-area {
        .single-short-service-area {
            display: flex;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid rgba(97, 94, 252, 0.2);

            p {
                margin: 0;
                margin-left: 20px;
                font-size: 20px;
                font-weight: 500;
                color: #111032;
            }
        }
    }

    .thumbnail-small-about {
        border-radius: 10px;
        overflow: hidden;
    }

    .button-wrapper {
        display: flex;
        align-items: center;
        margin-top: 45px;

        @media #{$large-mobile} {
            align-items: flex-start;
        }

        .call-infor-wrapper {
            display: flex;
            align-items: center;
            gap: 15px;

            .info {
                * {
                    color: var(--color-primary);
                }

                span {
                    display: block;
                }

                a {
                    font-size: 24px;
                }
            }
        }
    }
}



.bg_dark {
    background: #111032;
}

.rts-about-area {
    z-index: 1;

    .move-right {
        img {
            width: 100%;
        }
    }
}

.about-five-right-area {
    .pre-title {
        display: flex;
        align-items: center;
        color: #fff;
        gap: 10px;
    }

    .title {
        color: #ffff;
        font-size: 48px;
        margin-top: 15px;

        @media #{$smlg-device} {
            font-size: 34px;
        }

        @media #{$large-mobile} {
            font-size: 24px;
        }
    }

    p.disc {
        color: #FFFFFF;
        max-width: 85%;

        @media #{$large-mobile} {
            max-width: 100%;
            font-size: 16px;
        }
    }

}

.bottom-right-shape {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}


.about-seven-left-content {
    .pre {
        padding: 6px 15px;
        border-radius: 33px;
        border: 1px solid rgba(97, 94, 252, 0.2);
        margin-bottom: 70px;
        color: var(--color-primary);
        display: block;
        max-width: max-content;

        @media #{$sm-layout} {
            margin-bottom: 20px;
        }
    }

    p.disc-large {
        font-size: 30px;
        color: #111032;
        line-height: 38px;
        max-width: 100%;

        @media #{$large-mobile} {
            font-size: 18px;
            line-height: 1.4;
        }
    }

    .button-wrapper {
        display: flex;
        align-items: center;
        margin-top: 45px;

        .call-infor-wrapper {
            display: flex;
            align-items: center;
            gap: 15px;

            .info {
                * {
                    color: var(--color-primary);
                }

                span {
                    display: block;
                }

                a {
                    font-size: 24px;
                }
            }
        }
    }
}

.thumbnail-seven-about {
    position: relative;

    @media #{$mdsm-layout} {
        margin: auto;
        text-align: center;
    }

    .counter-up-area {
        position: absolute;
        left: -60px;
        bottom: -40px;
        background: #615EFC;
        border-radius: 10px;
        padding: 19px 25px;
        width: 170px;

        @media #{$mdsm-layout} {
            left: 10%;
        }

        @media #{$large-mobile} {
            left: 0%;
        }

        .icon {
            margin-bottom: 15px;
        }

        .title {
            font-size: 40px;
            font-family: var(--font-secondary);
            margin-top: 30px;
            margin-bottom: 5px;
            margin-top: 20px;
        }

        span {
            color: #fff;
        }
    }
}

.title-area-main-wrapper-left-nine {
    .pre-title {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 15px;
    }

    .title {
        font-size: 48px;
        @media #{$sm-layout} {
            font-size: 34px;
        }
    }

    p.disc {
        font-size: 16px;
    }
}


.about-inner-conetent-right-nine {
    padding-left: 70px;
    @media #{$mdsm-layout} {
        padding-left: 10px;
        margin-top: 30px;
    }
    .rating-area {
        display: flex;
        align-items: center;
        gap: 25px;
        @media #{$large-mobile} {
            flex-wrap: wrap;
        }
        img.main {
            max-width: 190px;
        }

        .rating-area {
            display: flex;
            flex-direction: column;
            gap: 8px;

            img {
                display: block;
            }

            p {
                color: #111032;
            }
        }
    }

    .button-bottom-about {
        display: flex;
        align-items: center;
        gap: 56px;
        margin-top: 25px;
        @media #{$sm-layout} {
            flex-wrap: wrap;
            gap: 25px;
        }
        .user-wrapper {
            display: flex;
            align-items: center;
            gap: 15px;
            .infor{
                .title{
                    margin-bottom: 2px;
                    font-weight: 500;
                    font-family: var(--font-secondary);
                }
                span{
                    color: #111032;
                }
            }
        }
    }
}
.title-between-area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media #{$mdsm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    .title {
        margin-bottom: 0;
    }

    p.disc {
        max-width: 520px;
    }
}

.single-team-area-start {
    position: relative;

    &.with-border {
        .thumbnail {
            border: 1px solid rgba(97, 94, 252, 1);
        }
    }

    .thumbnail {
        display: block;
        overflow: hidden;

        img {
            transition: .3s;
        }
    }

    &:hover {
        .thumbnail {
            img {
                transform: scale(1.06);
            }
        }
    }

    .bottom {
        text-align: center;
        margin-top: 30px;

        .title {
            font-size: 20px;
            margin-bottom: 10px;
            transition: .3s;

            &:hover {
                color: var(--color-primary);
            }
        }

        p {
            margin-bottom: 10px;
        }

        .social-area-tranaparent {
            justify-content: center;

            ul {
                li {
                    a {
                        i {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }

    &.team-absolute-bottom {
        position: relative;

        .thumbnail {
            border-radius: 10px;
            display: block;
            overflow: hidden;

            img {
                transition: .3s;
            }
        }

        .bottom {
            margin-top: 0;
            position: absolute;
            left: 50%;
            min-width: max-content;
            transform: translateX(-50%);
            background: #FFFFFF;
            padding: 15px 23px;
            bottom: -45px;
            border-radius: 10px;
            border: 1px solid var(--color-primary);
            transition: .3s;

            .social-area-tranaparent {
                margin-bottom: 10px;

                i {
                    transition: .3s;
                }
            }

            a {
                .title {
                    margin-bottom: 5px;
                    transition: .3s;
                }
            }

            p {
                transition: .3s;
            }
        }

        &:hover {
            .thumbnail {
                img {
                    transform: scale(1.1);
                }
            }

            .bottom {
                background: var(--color-primary);

                .title {
                    color: #fff;
                }

                i {
                    color: #fff;
                }

                p {
                    color: #fff;
                }
            }
        }
    }
}

.swiper {
    overflow: hidden;
}


.single-team-area-start.team-radious-style {
    .thumbnail {
        border-radius: 50%;
        overflow: hidden;
        display: block;
        height: 300px;
        width: 300px;
        margin: auto;

        @media #{$mdsm-layout} {
            height: 250px;
            width: 250px;

        }

        @media #{$sm-layout} {
            height: 200px;
            width: 200px;
        }
    }
}

.rts-apponemnt-area-5-content {
    .call-infor-wrapper {
        display: flex;
        align-items: center;
        gap: 15px;

        .info {
            * {
                color: var(--color-primary);
            }

            span {
                display: block;
            }

            a {
                font-size: 24px;
            }
        }
    }
}

.title-area-left {
    .pre-title {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--color-primary);
    }
}

.rts-appoinment-area {
    z-index: 1;

    .right-bottom-area {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        z-index: -1;

        img {
            max-width: 259.15px;
        }
    }
}

.single-details-content-info {
    height: 100%;

    .title {
        font-size: 20px;
        margin-bottom: 18px;
        color: #111032;
    }

    .inner {
        padding: 18px 13px;
        border-radius: 10px;
        background: #F0F0FF;

        p {
            margin-bottom: 0px;
            color: #111032;
            font-weight: 400;
            font-size: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.swiper-container-h1-team-8{
    .team-radious-style{
        .thumbnail{
            height: 207px;
            width: 207px;
            border-color: rgba(97, 94, 252, 0.2);
            transition: .3s;
            &:hover{
                border-color: var(--color-primary);
            }
        }
    }
}